<template>
  <div class="container pt-3">
    <div class="box-card mt-3">
      <div class="titleStyle">
        <h1 class="primaryColor" style="font-size: 30px;">
          {{ title }}
        </h1>
        <el-divider></el-divider>
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane :label="$t('userProfile.basicInfo')" name="info">
            <Heading :content="$t('userProfile.basic.title')"></Heading>
            <hr />
            <el-form
              v-if="userInfo"
              ref="form"
              :model="userInfo"
              label-width="120px"
            >
              <el-form-item :label="'Name'">
                <b>{{ title }}</b>
              </el-form-item>
              <template>
                <el-form-item :label="$t('userProfile.basic.email')">
                  <b class="text-success">
                    <span>
                      {{ userInfo.email }}
                      <i class="el-icon-success"></i>
                    </span>
                  </b>
                </el-form-item>
              </template>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import { instant, user } from "@ivy-way/material";
import userApi from "@/apis/user";
import SAT from "@/apis/sat";
import baseDomainPath from "@/common/baseDomainPath";
import Theme from "@/common/theme";
import moment from "moment";

export default {
  metaInfo() {
    return {
      title: `${this.title} - ${this.CompanyName}`
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      moment,
      referral_code: null,
      referral: null,
      userCorrect: 0,
      userInfo: null,
      userProfile: null,
      tag_analysis: null,
      studentClasses: null,
      // studentResultsTotal: 0,
      // studentPageSize: 9,
      teacherClasses: null,
      // teacherResultsTotal: 0,
      // teacherPageSize: 9,
      testResults: null,
      activeName: "info",
      studentPage: {
        pageSize: 6,
        page: 1,
        total: 0
      },
      teacherPage: {
        pageSize: 6,
        page: 1,
        total: 0
      },
      resultsPage: {
        pageSize: 10,
        page: 1,
        total: 0
      }
    };
  },
  computed: {
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    theme() {
      return Theme;
    },
    link() {
      return `${baseDomainPath}register?referral_code=${this.referral_code}`;
    },
    tag_analysis_arr() {
      let res = null;
      if (this.tag_analysis) {
        res = _.groupBy(this.tag_analysis, "tag_type");
      }
      return res;
    },
    userId() {
      return this.getProfile.id;
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    teacherViewUser() {
      return (
        this.$route.name == "TeacherViewUser" ||
        this.$route.name == "CompanyAdminViewUser"
      );
    },
    myProfile() {
      return this.$route.name === "UserProfile";
    },
    adminViewUser() {
      return this.$route.name == "AdminViewUser";
    },
    title() {
      if (this.userInfo) {
        return user.displayName(
          this.userInfo.first_name,
          this.userInfo.last_name
        );
      } else {
        return " - ";
      }
    }
  },
  watch: {
    userId(userId) {
      if (userId) {
        this.getUserProfile();
      }
    }
  },
  mounted() {
    if (this.$route.name === "UserProfile") {
      this.activeName = "info";
    }
    if (this.$route.query.active) {
      this.activeName = this.$route.query.active;
    }
    if (this.userId) {
      this.getUserProfile();
    }
  },
  methods: {
    viewInvitation() {
      this.activeName = "per";
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({
        message: this.$t("Profile.Copied"),
        type: "success"
      });
    },
    testResultsSetPage(page) {
      this.resultsPage.page = page;
      this.getUserResults();
    },
    studentSetPage(page) {
      this.studentPage.page = page;
      this.getUserClasses();
    },
    teacherSetPage(page) {
      this.teacherPage.page = page;
      this.getTeacherClasses();
    },
    async getUserProfile() {
      const res = this.getProfile;
      this.userInfo = res;
      this.userProfile = res;
    },
    async getUserTagAnalysis() {
      const res = await SAT.getUserTagAnalysis(this.userId);
      this.tag_analysis = res ? res : null;
    },
    async getUserResults() {
      let role = "admin";
      if (this.teacherViewUser) {
        role = "teacher";
      }
      const res = await userApi.getUserResults(role, this.userId, {
        page: this.resultsPage.page,
        per_page: this.resultsPage.pageSize
      });
      this.testResults = res ? res.user_exams.data : null;
      this.resultsPage.total = res ? res.user_exams.total : null;
    },
    async getUserClasses() {
      const res = await userApi.getClasses({
        student_user_id: this.userId,
        per_page: this.studentPage.pageSize,
        page: this.studentPage.page
      });
      this.studentClasses = res.session_classes.data;
      this.studentPage.total = res.session_classes.total;
      // this.tag_analysis = res ? res : null;
    },
    async getTeacherClasses() {
      const res = await userApi.getClasses({
        teacher_user_id: this.userId,
        per_page: this.teacherPage.pageSize,
        page: this.teacherPage.page
      });
      this.teacherClasses = res.session_classes.data;
      this.teacherPage.total = res.session_classes.total;
      // this.tag_analysis = res ? res : null;
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  border: 1px solid #ebeef5;
  padding: 0 20px;
  margin-right: 10px;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
}
.box-card {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.proIcon {
  display: inline-block;
  margin: 0px 2px;
  color: orange;
}
</style>
